<template>
  <div class="error-page d-flex align-center justify-center">
    <div class="error-page__wrapper d-flex">
      <div class="error-page__graphic">
        <img src="@/assets/images/logo.svg" class="error-page__image" alt="WMTScan" />
      </div>
      <div class="error-page__content">
        <h1 class="error-page__title">
          WMTscan will be back soon
        </h1>
        <div class="error-page__text">
          <p class="error-page__p">
            WMTscan is temporary down for maintenance, we'll be back soon so don't forget to check again in a while.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  created () {

  },
  beforeDestroy () {
  },
  data () {
    return {}
  },
  computed: {},
  methods: {},
  watch: {}
}
</script>

<style lang="scss" scoped>
.error-page {
  width: 100%;
  min-height: 100vh;
  background: #000;
  padding: 140px 16px;

  &::before,
  &::after {
    content: "";
    position: absolute;
    left: 0%;
    right: 100%;
    width: 100%;
    height: 140px;
  }

  &::before {
    top: 0;
    background: linear-gradient(0deg, #1A0022 0.03%, #000000 100%);
    transform: matrix(1, 0, 0, -1, 0, 0);;
  }

  &::after {
    bottom: 0;
    background: linear-gradient(0deg, #1A0022 0.03%, #000000 100%);
    transform: matrix(-1, 0, 0, 1, 0, 0);
  }

  &__wrapper {
    max-width: 710px;
  }

  &__graphic {
    width: 60px;

    @media (min-width: 959px) {
      width: 80px;
    }
  }

  &__image {
    display: block;
    max-width: calc(100% - 8px);
    margin-right: 8px;
    margin-top: 10px;

    @media (min-width: 959px) {
      max-width: calc(100% - 16px);
      margin-right: 16px;
    }
  }

  &__content {
    width: 80%;
    padding-left: 15px;
    position: relative;

    @media (min-width: 959px) {
      padding-left: 50px;
      width: 75%;
    }

    &::before {
      content: "";
      position: absolute;
      top: 5px;
      left: 0;
      bottom: 10px;
      width: 2px;
      background: var(--v-accent-base)
    }
  }

  &__title {
    font-size: 44px;
    line-height: 1.2;
    font-weight: 600;
    color: white;
    margin: 0 0 32px;

    @media (min-width: 959px) {
      font-size: 72px;
       margin: 0 0 40px;
    }
  }

  &__text {
    color: white;
  }

  &__p {
    font-size: 18px;
    line-height: 1.775;
    margin: 0;

    &:not(:last-child) {
      margin-bottom: 32px;

      @media (min-width: 959px) {
        margin-bottom: 40px;
      }
    }
  }

  &__link {
    cursor: pointer;
    text-decoration: underline;
  }
}

</style>
